@import url('https://fonts.googleapis.com/css2?family=BenchNine:wght@300;400;700&family=Six+Caps&family=Source+Sans+Pro:ital,wght@0,200;0,300;0,400;0,600;0,700;0,900;1,200&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Syne:wght@400;500;600;700;800&family=Teko:wght@300;400;500;600;700&display=swap');

@font-face {
  font-family: "Delirium";
  src: url("./fonts/Delirium.ttf");
}

/* 
gray : #e5ece9 
yellow : #e7fd8a
*/

:root {
  --white: #F0EFEB;
  --black: #2F2C1C;
  --gray: #D2D2D2;
  --lightGray: #F0EFEB;
  --white: #FAF9F9;
  --monocolor: #F0AB5D;
  --monocolorCardHover: #D69954;
  --monocolorCardHoverText: #966C3B;
}

*, 
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  cursor: none;
}

html {
  scroll-behavior: smooth;
  overscroll-behavior: none;
  scrollbar-width: none;
}
html:focus-within { scroll-behavior: smooth; }

body {
  z-index: 1;
  font-family: "Source Sans Pro", sans-serif;
  overscroll-behavior: none;
  scroll-behavior: smooth;
  height: auto;
  width: 100vw;
}
body::-webkit-scrollbar { display: none; }

h1 { font-weight: 800; }

*::selection {
  color: var(--lightGray);
  background-color: var(--black);
}

header.nav-hidden {
  transform: translateY(-100%);
  transition: transform 0.6s ease-in-out;
}


/* CURSOR ON DESKTOP */
#cursor {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  position: fixed;
  transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  pointer-events: none;
  z-index: 9999999;

  background: var(--monocolor);

  transition: all 350ms;
  transition-property: opacity, background-color, transform;
}
/* CURSOR HIDDEN ON MOBILE */
@media (pointer: none), (pointer: coarse) {
  .cursor {
    display: none !important;
    visibility: hidden;
    opacity: 0;
  }
  * { cursor: auto !important; }
}


/* MAIN SECTION - SCROLL TO TOP */
#main__section {
  width: 100vw;
}


/* CARD - NAVLINK */
.card {
  display: block;
  text-decoration: none;
  width: 200px;
  padding: 10px 10px 20px 10px;
  border-radius: 3px;
  background-color: #FFFFFF;
  transform: rotate(-5deg);
  willchange: transform;
  box-shadow: 5px 5px 20px #2F2C1C30;
  z-index: 2;
}
.project__card {
  height: 245px;
}
.card::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 0%;
  border-radius: 3px;
  background-color: var(--monocolorCardHover);
  transition: height .7s ease-in-out;
  z-index: -1;
}
.card:hover::after,
.card:focus::after {
  height: 100%;
}
.card:hover p { color: var(--white); }
.card:hover span { color: var(--monocolorCardHoverText); } 
.card:hover #rounded { background: var(--monocolorCardHoverText); }

.card p {
  text-transform: uppercase;
  color: var(--black);
  transition: color .7s ease-in-out;
}
.card__title {
  font-size: 16px;
  font-weight: 400;
}
.card__desc {
  width: 80%;
  margin-top: 20px;
  font-size: 13px;
  font-weight: 300;
}
.card__rounded {
  width: 30px;
  height: 30px;
  margin: 70px 0 20px 65%;
  background: var(--black);
  border-radius: 50%;
  transition: background .6s ease-in-out;
}
.card__rounded__wrapper {
  position: relative;
  width: 30px;
  height: 30px;
  font-size: 12px;
  font-weight: 600;
  margin: 0 auto;
  -webkit-animation: spin 10s linear infinite;
     -moz-animation: spin 10s linear infinite;
          animation: spin 10s linear infinite;
}
.card__rounded__wrapper span {
  position: absolute;
  top: 50%;
  left: 0%;
  transform: translate(-50%, -50%);
  width: 30px;
  height: 30px;
  color: var(--black);
  margin-right: -25px;
  line-height: 70px;
  text-align: center;
  transform-origin: top center;
}
.card__rounded__wrapper span:nth-of-type(1) { transform: rotate(75deg); }
.card__rounded__wrapper span:nth-of-type(2) { transform: rotate(50deg); }
.card__rounded__wrapper span:nth-of-type(3) { transform: rotate(25deg); }
.card__rounded__wrapper span:nth-of-type(4) { transform: rotate(0deg); }
.card__rounded__wrapper span:nth-of-type(5) { transform: rotate(-25deg); }
.card__rounded__wrapper span:nth-of-type(6) { transform: rotate(-50deg); }
.card__rounded__wrapper span:nth-of-type(7) { transform: rotate(-75deg); }
.card__rounded__wrapper span:nth-of-type(8) { transform: rotate(-100deg); }
.card__rounded__wrapper span:nth-of-type(9) { transform: rotate(-125deg); }
.card__rounded__wrapper span:nth-of-type(10) { transform: rotate(-150deg); }
.card__rounded__wrapper span:nth-of-type(11) { transform: rotate(-175deg); }
.card__rounded__wrapper span:nth-of-type(12) { transform: rotate(-200deg); }
.card__rounded__wrapper span:nth-of-type(13) { transform: rotate(-225deg); }
.card__rounded__wrapper span:nth-of-type(14) { transform: rotate(-250deg); }


/* HERO */
#hero {
  width: 100vw;
  background-color: var(--white);
  transition: background-color 0.4s ease-in-out;
}
#hero.active { background-color: var(--gray); }
#hero::-webkit-scrollbar { display: none; }

#hero__banner {
  width: 100%;
  min-height: 100vh;
  padding: 140px 5%;
  display: flex;
  flex-direction: column-reverse;
  color: var(--monocolor);
}
#hero__banner__bg {
  width: 80%;
  height: 1550px;
  position: absolute;
  top: 120px;
  left: 50%;
  transform: translateX(-50%);
  background-image: linear-gradient(#F0EFEB50, #F0EFEB99), url('../public/images/noise-transparent.png');
}
#hero__banner__title {
  width: fit-content;
  margin-top: 100px;
  align-self: center;
  font-family: "Delirium", sans-serif;
  font-weight: 400;
  text-transform: uppercase;
  font-size: clamp(100px, 15vw, 360px);
  line-height: clamp(100px, 15vw, 360px);
  word-break: break-word;
  z-index: 2;
  transform: translate(0, 0);
}
#hero__banner__subtitle {
  width: 100%;
  text-align: right;
  align-self: flex-end;
  font-weight: 400;
  text-transform: uppercase;
  font-size: clamp(100px, 15vw, 140px);
  line-height: clamp(100px, 15vw, 140px);
  word-break: break-word;
  z-index: 2;
  font-family: "Delirium", sans-serif;
}
#hero__banner__location {
  width: fit-content;
  margin-top: 50px;
  padding-top: 10px;
  border-top: 2px solid var(--monocolor);
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: flex-start;
  text-align: right;
  font-weight: 300;
  text-transform: uppercase;
  font-size: 30px;
  word-break: break-word;
  z-index: 2;
  font-family: "Delirium", sans-serif;
}
@media screen and (min-width: 768px) { 
  #hero__banner__bg {
    height: 1250px; 
  }
}

/* HERO - ABOUT SECTION */
#hero__about {
  width: 100%;
  height: fit-content;
  padding: 200px 0 0 0;
  position: relative;
}
.hero__about__line {
  width: 90%;
  margin: 0 auto 100px auto;
  height: 40px;
  border-top: 5px solid var(--white);
  border-bottom: 5px solid var(--white);
}
.hero__about__item {
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.hero__about__item__text {
  width: 90%;
  margin: 0 auto 50px auto;
  color: var(--black);
  font-weight: 400;
  text-transform: uppercase;
  font-size: clamp(20px, 10vw, 25px);
  line-height: clamp(20px, 10.5vw, 30px);
}
@media screen and (min-width: 768px) {
  .hero__about__line,
  .hero__about__item {
    width: 80%;
  }
  .hero__about__item {
    margin: 0 auto;
    flex-direction: row;
    align-items: flex-end;
  }
  .hero__about__item__text {
    width: 70%;
    margin-right: 50px;
    margin: 0 10% 50px 5px;
    padding-bottom: 200px;
  }
}

/* HERO - PROJECTS SECTION */
#hero__projects {
  width: 90%;
  margin: 0 auto;
  height: fit-content;
  padding-top: 1050px; 
  position: relative;

  display: flex;
  flex-direction: column;
}
.hero__projects__title {
  padding: 100px 0 200px 0;
  font-family: "Delirium", sans-serif;
  text-transform: uppercase;
  text-align: center;
  font-size: clamp(85px, 14vw, 220px);
  color: var(--lightGray);
  position: sticky;
  top: 20%;
  z-index: 1;
  mix-blend-mode: difference;
}

.hero__projects__item {
  width: 100%;
  display: flex;
  flex-direction: column;
}
.hero__projects__item:nth-child(1) { align-items: center; }
.hero__projects__item:nth-child(2) { align-items: flex-end; }
.hero__projects__item:nth-child(3) { align-items: flex-start; }
.hero__projects__item:nth-child(4) { align-items: flex-end; }
.hero__projects__item:nth-child(5) { align-items: center; }
.hero__projects__item:nth-child(6) { align-items: flex-end; }
.hero__projects__item:nth-child(7) { align-items: center; }
.hero__projects__item:nth-child(8) { align-items: flex-end; }
.hero__projects__item:nth-child(9) { align-items: flex-start; }
.hero__projects__item:nth-child(10) { align-items: flex-end; }

.hero__projects__item a:nth-child(1n + 1) { align-self: center; }

.project__image {
  width: 70%;
  height: 500px;
  object-fit: cover;
}
@media screen and (max-width: 500px) {
  .project__image {
    width: 80%;
    height: 350px;
  }
}

/* HERO - PROJECT STICKY TRANSITION */
#zoom__sticky {
  position: sticky;
  top: 0;
  width: 100%;
  height: 100vh;
  overflow: hidden;
}
#zoom__sticky__project {
  width: 100%;
  height: 100vh;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}
#zoom__sticky__project__text {
  position: absolute;
  width: fit-content;
  height: fit-content;
  font-family: "Delirium", sans-serif;
  color: var(--gray);
  font-weight: 600;
  text-transform: uppercase;
  font-size: clamp(120px, 15vw, 140px);
  line-height: clamp(120px, 15vw, 140px);
  overflow: hidden;
}


/* FOOTER */
#main__footer {
  width: 100vw;
  height: 100vh;
  position: relative;
  padding: 10vh 5% 10px 5%;
}
.main__footer__bg {
  width: 80%;
  height: 90vh;
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  background-image: linear-gradient(#F0EFEB50, #F0EFEB99), url('../public/images/noise-transparent.png');
}
.main__footer__desc {
  position: relative;
  width: 90%;
  max-width: 410px;
  display: block;
  text-decoration: none;
  text-transform: uppercase;
  font-size: clamp(15px, 10vw, 17.5px);
  color: var(--black);
  z-index: 2;
}
.main__footer__links {
  position: relative;
  width: 100%;
  height: 80%;
  padding: 50px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}
.main__footer__links__wrap {
  width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
.main__footer__links__item {
  display: block;
  width: fit-content; 
  text-decoration: none;
  color: var(--black);
  position: relative;
  font-weight: 400;
  margin: 10px 0;
  transition: color 1.2s ease, transform 1.2s ease;
}
.main__footer__links__item span { position: relative; }
.main__footer__links__item span::after {
  content: "";
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 4px;
  bottom: -5px;
  left: 0;
  background-color: var(--monocolor);
  transform-origin: bottom right;
  transition: transform 0.4s cubic-bezier(0.86, 0, 0.07, 1);
}
.main__footer__links__item:hover span::after {
  transform: scaleX(1);
  transform-origin: bottom left;
}
.main__footer__links__item:hover { 
  color: var(--monocolor); 
  transform: translateX(10px);
}
.main__footer__mailto {
  position: relative;
  width: fit-content;
  display: block;
  font-family: "Delirium", sans-serif;
  text-decoration: none;
  font-size: clamp(150px, 40vw, 300px);
  text-transform: uppercase;
  font-weight: 900;
  color: var(--monocolor);
  letter-spacing: 0.2rem;
  transition: color 1.2s ease, transform 1.2s ease;
}
.main__footer__mailto span { 
  position: relative; 
  height: 100%; 
  display: inline-block;
}
.main__footer__mailto span:nth-of-type(1) { transition: transform 0.6s ease-in-out;}
.main__footer__mailto span:nth-of-type(2) { transition: transform 0.9s ease-in-out; }
.main__footer__mailto span:nth-of-type(3) { transition: transform 1.2s ease-in-out; }
.main__footer__mailto span:nth-of-type(4) { transition: transform 1.5s ease-in-out; }
.main__footer__mailto span:nth-of-type(5) { transition: transform 1.8s ease-in-out; }
.main__footer__mailto span:nth-of-type(6) { transition: transform 2.1s ease-in-out; }
.main__footer__mailto:hover span:nth-of-type(1) { transform: translateY(20px) skew(-10deg); }
.main__footer__mailto:hover span:nth-of-type(2) { transform: translateY(-30px) skew(10deg); }
.main__footer__mailto:hover span:nth-of-type(3) { transform: translateY(20px) skew(-5deg); }
.main__footer__mailto:hover span:nth-of-type(4) { transform: translateY(-30px) skew(-10deg); }
.main__footer__mailto:hover span:nth-of-type(5) { transform: translateY(-30px) skew(5deg); }
.main__footer__mailto:hover span:nth-of-type(6) { transform: translateY(20px) skew(10deg); }

.main__footer__mention {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: var(--black);
  font-size: 12.5px;
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
}
.main__footer__mention__siret {
  padding-bottom: 5px;
}
.main__footer__mention__dev {
  text-transform: uppercase;
}
@media screen and (min-width: 768px){
  .main__footer__mention {
    flex-direction: row;
    justify-content: space-evenly;
  }
  .main__footer__mention__siret { padding-bottom: 0px; }
}


/* DETAIL */
#detail {
  width: 100%;
  height: 100%;
  background-color: var(--white);
  color: var(--black);
  font-weight: 300;
  text-transform: uppercase;

  transition: background-color 0.4s ease-in-out;
}
#detail.active {
  background-color: var(--black);
}

/* DETAIL - HERO / BANNER */
#detail__banner {
  width: 100%;
  min-height: 100vh;
  padding: 140px 5%;
  display: flex;
  flex-direction: column;
  color: var(--monocolor);
  font-family: "Delirium", sans-serif;
}
#detail__banner__bg {
  width: 80%;
  height: calc(100vh - 270px);
  position: absolute;
  top: 120px;
  left: 50%;
  transform: translateX(-50%);
  background-image: linear-gradient(#F0EFEB50, #F0EFEB99), url('../public/images/noise-transparent.png');
}
#detail__banner__title {
  width: 100%;
  align-self: flex-start;
  font-size: clamp(120px, 15vw, 140px);
  line-height: clamp(120px, 15vw, 140px);
  font-weight: 400;
  word-break: break-word;
  z-index: 2;
}
#detail__banner__infos {
  width: fit-content;
  margin-top: 50px;
  padding-top: 10px;
  border-top: 2px solid var(--monocolor);
  justify-self: flex-end;
  align-self: end;

  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-weight: 300;
  letter-spacing: 1px;
  font-size: 35px;
  z-index: 2;
}
#detail__banner__img {
  width: 100%;
  position: relative;
  position: absolute;
  top: 70%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
  justify-content: center;
}
#detail__banner__img svg {
  width: 90%;
	max-width: 550px;
	height: auto;
}

/* DETAIL - TRANSITON (BONJOUR / BONSOIR) */
#detail__sticky {
  position: sticky;
  top: 0;
  width: 100%;
  height: 100vh;
  overflow: hidden;
}
#detail__sticky__zoom {
  width: 100%;
  height: 100vh;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}
#zoom__hello {
  position: absolute;
  width: fit-content;
  height: fit-content;
  font-family: "Delirium", sans-serif;
  color: var(--black);
  font-weight: 600;
  text-transform: uppercase;
  font-size: clamp(120px, 15vw, 140px);
  line-height: clamp(120px, 15vw, 140px);
  overflow: hidden;
}

/* DETAIL - DESCRIPTION */
#detail__description {
  width: 100%;
  height: auto;
  padding: 700px 5% 200px 5%;
  position: relative;
}
#detail__description_header {
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  color: var(--white);
}
.detail__description_header_item {
  width: fit-content;
  border-top: 0.8px solid var(--white);
}
.detail__description_header_item__title,
.detail__description_header_item__text {
  text-transform: uppercase;
  font-weight: 300;
  font-size: 18px;
}

#detail__description_infos {
  width: 100%;
  margin: 150px 0 0 0;
}
.detail__description_infos__item { width: 90%; }
.detail__description_infos__item__p {
  margin-bottom: 50px;
  color: var(--white);
  text-transform: uppercase;
  font-size: clamp(18px, 10vw, 20px);
  line-height: clamp(23px, 10.5vw, 25px);
  font-weight: 300;
}
.detail__description_infos__item__p span {
  font-weight: 400;
  transition: color .8s ease-in-out;
  z-index: 20;
}
.detail__description_infos__item__p span:hover { 
  color: var(--monocolor);
}

@media screen and (min-width: 768px) {
  #detail__description {
    display: flex;
    align-items: flex-start;
  }
  #detail__description_header {
    height: 200px;
    width: 200px;
    flex-direction: column;
  }
  #detail__description_infos {
    width: 80%; 
    margin: 0 0 0 12.5%;
  }
}

/* DETAIL - QUOTE */
#detail__quote {
  width: 90%;
  margin: 100px auto 50px auto;
  font-size: clamp(40px, 10vw, 100px);
  line-height: clamp(38px, 9.8vw, 98px);
  letter-spacing: -3px;
  font-weight: 600;
}

/* DETAIL - CTA TO GITHUB REPO */
#detail__goto__git {
  width: 90%;
  margin: 0px 5% 100px 5%;
  padding: 5px 0;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  z-index: 100;
}
#detail__goto__git a {
  display: block;
  width: fit-content; 
  text-decoration: none;
  color: var(--black);
  position: relative;
  font-weight: 400;
  transition: color 1.2s ease, transform 1.2s ease;
}
#detail__goto__git a span { position: relative; }
#detail__goto__git a span::after {
  content: "";
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 4px;
  bottom: -5px;
  left: 0;
  background-color: var(--monocolor);
  transform-origin: bottom right;
  transition: transform 0.4s cubic-bezier(0.86, 0, 0.07, 1);
}
#detail__goto__git a:hover span::after {
  transform: scaleX(1);
  transform-origin: bottom left;
}
#detail__goto__git a:hover { 
  color: var(--monocolor); 
  transform: translateX(10px);
}


/* PROJECT TEMPLATE */
#project {
  height: auto;
  margin: 0;
  padding: 0;
  overflow-x: hidden !important;
  overflow-y: scroll;
}

/* PROJECT TEMPLATE - BANNER */
#project__banner {
  width: 100vw;
  height: 100vh;
  padding: 80px 5%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}
#project__banner__img {
  width: 80%;
  height: calc(100% - 160px);
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  object-fit: cover;
  filter: grayscale(1);
}
#project__banner__title {
  width: 100vw;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Delirium", sans-serif;
  color: var(--monocolor);
  font-size: clamp(100px, 15vw, 300px);
  line-height: clamp(100px, 15vw, 300px);
  letter-spacing: 5px;
  z-index: 10;
}
#project__banner__title span:nth-of-type(1) { transform: translateY(-10vh); }
#project__banner__title span:nth-of-type(2) { transform: translateY(-20vh); }
#project__banner__title span:nth-of-type(3) { transform: translateY(-12vh); }
#project__banner__title span:nth-of-type(4) { transform: translateY(5vh); }
#project__banner__title span:nth-of-type(5) { transform: translateY(-5vh); }
#project__banner__title span:nth-of-type(6) { transform: translateY(20vh); }
#project__banner__title span:nth-of-type(7) { transform: translateY(-20vh); }
#project__banner__title span:nth-of-type(8) { transform: translateY(10vh); }
#project__banner__title span:nth-of-type(9) { transform: translateY(0vh); }
#project__banner__title span:nth-of-type(10) { transform: translateY(18vh); }
#project__banner__title span:nth-of-type(11) { transform: translateY(0vh); }
#project__banner__title span:nth-of-type(12) { transform: translateY(10vh); }
#project__banner__title span:nth-of-type(13) { transform: translateY(-15vh); }
#project__banner__title span:nth-of-type(14) { transform: translateY(5vh); }
#project__banner__title span { transition: transform 0.6s ease-in-out; }
#project__banner__title :hover span { transform: translate(0px, 0vh); }

.project__rounded {
  position: absolute;
  bottom: 5%;
  right: 10%;
  width: 30px;
  height: 30px;
}

/* PROJECT TEMPLATE - DESCRIPTION */
#project__description {
  width: 100%;
  height: auto;
  padding: 200px 5% 0 5%;
}
#project__description__content {
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}
.project__description__content__item {
  width: fit-content;
  border-top: 0.5px solid var(--black);
}
.project__description__content__item__title {
  text-transform: uppercase;
  font-weight: 400;
  font-size: 18px;
  font-weight: 200;
}
.project__description__content__item__text {
  text-transform: uppercase;
  color: var(--black);
  font-weight: 200;
}
#project__description__info {
  width: 100%;
  margin: 150px 0 0 0;
}
.project__description__info__item {
  width: 90%;
}
.project__description__info__item__p {
  margin-bottom: 50px;
  color: var(--black);
  text-transform: uppercase;
  font-size: clamp(18px, 10vw, 20px);
  line-height: clamp(23px, 10.5vw, 25px);
  font-weight: 300;
}

@media screen and (min-width: 768px) {
  #project__description {
    display: flex;
    align-items: flex-start;
  }
  #project__description__content {
    height: 200px;
    width: 150px;
    flex-direction: column;
  }
  #project__description__info {
    width: 80%; 
    margin: 0 0 0 12.5%;
  }
}

/* PROJECT TEMPLATE - SVG GSAP ANIMATION */
#project__svg__container {
  width: 100%;
  height: auto;
  padding: 0px 5% 50px 5%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}
#project__svg__title {
  width: fit-content;
  align-self: flex-end;
  margin: 150px 0 0 0;
  border-top: 1.5px solid var(--black);
  font-size: clamp(50px, 10vw, 70px);
  line-height: clamp(50px, 10vw, 70px);
  text-transform: uppercase;
  color: var(--black);
  font-weight: 300;
  letter-spacing: -2px;
  text-align: right;
  height: fit-content;
}
#project__svg__container svg {
  max-width: 100%;
  height: auto;
}
#project__svg__container svg image {
  filter: grayscale(90%);
  transition: filter 0.6s ease-in-out;
}
#project__svg__container svg:hover image {
  filter: grayscale(0%);
}

#project::-webkit-scrollbar { display: none; }
#project__goto__site {
  display: block;
  margin: 0 5%;
  width: 70%; 
  text-decoration: none;
  color: var(--black);
  text-transform: uppercase;
  position: relative;
  font-weight: 400;
  transition: color 1.2s ease, transform 1.2s ease;
}
#project__goto__site span { position: relative; }
#project__goto__site span::after {
  content: "";
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 4px;
  bottom: -5px;
  left: 0;
  background-color: var(--monocolor);
  transform-origin: bottom right;
  transition: transform 0.8s cubic-bezier(0.86, 0, 0.07, 1);
}
#project__goto__site:hover span::after {
  transform: scaleX(1);
  transform-origin: bottom left;
}
#project__goto__site:hover { 
  color: var(--monocolor); 
  transform: translateX(15px);
}

/* PROJECT TEMPLATE - FOOTER TO NEXT PROJECT */
#next__project__footer {
  width: 100vw;
  min-height: 80vh;
  position: relative;
  margin-top: 200px;
  padding: 10vh 5% 10px 5%;
  display: flex;
  align-items: end;
  justify-content: center;
  overflow: hidden;
}
#next__project__footer__bg {
  width: 80%;
  height: 70vh;
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  background-image: linear-gradient(#F0EFEB50, #F0EFEB99), url('../public/images/noise-transparent.png');
}
#next__project__footer__marquee {
  display: flex-wrap;
  white-space: nowrap;
  position: absolute;
  top: 50%;
  left: 0%;
  transform: translateY(-50%);
  animation: 10s linear infinite marqueeText;
}
#next__project__footer__marquee span {
  width: fit-content;
  color: var(--black);
  font-family: "Delirium", sans-serif;
  font-size: 250px;
  text-transform: uppercase;
}

#next__project__card {
  
}


/* TEXT ROTATED BEHIND HOME CURRENT IMAGE */
@-moz-keyframes infiniteText { 
  100% { -moz-transform: translateY(600%) rotate(-90deg); } 
}
@-webkit-keyframes infiniteText {
  100% { -webkit-transform: translateY(600%) rotate(-90deg); }
}
@keyframes infiniteText {
  100% { -webkit-transform: translateY(600%) rotate(-90deg);
                transform: translateY(600%) rotate(-90deg);
  }
}

/* ROTATE INFINITE ANIMATION */
@-moz-keyframes spin { 
  100% { -moz-transform: rotate(360deg); } 
}
@-webkit-keyframes spin { 
  100% { -webkit-transform: rotate(360deg); } 
}
@keyframes spin { 
  100% { 
      -webkit-transform: rotate(360deg); 
      transform:rotate(360deg); 
  } 
}

/* ROTATE INFINITE ANIMATION */
@-moz-keyframes marqueeText { 
  100% { -moz-transform: translate(-912.75px, -50%); } 
}
@-webkit-keyframes marqueeText { 
  100% { -webkit-transform: translate(-912.75px, -50%); } 
}
@keyframes marqueeText { 
  100% { 
      -webkit-transform: translate(-912.75px, -50%); 
      transform: translate(-932.75px, -50%); 
  } 
}